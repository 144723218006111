import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

export interface ILink {
  label: React.ReactNode
  href: string
  icon?: React.ReactNode
  isDirectLink?: boolean
}

export interface ISidebarProps {
  links: ILink[]
  className?: string
}

const SidebarNav = ({ links = [], className }: ISidebarProps) => {
  const router = useRouter()
  return (
    <ul className={`py-4 px-3 ${className}`}>
      {links.map((link, key) => {
        const isActiveClass =
          router.pathname === link.href
            ? 'bg-grey200 font-bold border rounded'
            : ''
        return (
          <li key={key}>
            <Link
              href={link.href}
              className={`${isActiveClass} flex items-center my-2 px-3 py-2 rounded transition-all duration-200 hover:font-bold hover:bg-gray-100`}
            >
              {link.icon}
              <span className="px-2">{link.label}</span>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}
export default SidebarNav
